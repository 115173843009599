import styled from "@emotion/styled"
import {
  defaultBoxShadow,
  dark800,
  red900,
  dark900,
  containerPadding,
} from "../Layout/index.style"

interface Props {
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
}

const HighlightedSection = styled.section((props: Props) => {
  const { primary, secondary, tertiary } = props
  let backgroundColor = ""
  if (primary) {
    backgroundColor = dark800
  }
  if (secondary) {
    backgroundColor = red900
  }
  if (tertiary) {
    backgroundColor = dark900
  }
  return {
    boxShadow: defaultBoxShadow,
    backgroundColor,
    width: "100%",
    padding: containerPadding,
    color: "white",
    maxWidth: "100%",
    boxSizing: "border-box",
  }
})

export default HighlightedSection
