import styled from "@emotion/styled"
import { dark1000, font } from "../../containers/Layout/index.style"

const H3 = styled.h3(
  {
    margin: "0px",
    fontFamily: font,
    fontSize: "24px",
    color: dark1000,
    fontWeight: "normal",
  },
  props => ({
    color: props.color,
  })
)

export default H3
