import styled from "@emotion/styled"
import { font, dark1000 } from "../../containers/Layout/index.style"

const A = styled.a({
  fontSize: "16px",
  fontWeight: "normal",
  fontFamily: font,
  backgroundColor: "transparent",
  color: dark1000,
})

export default A
