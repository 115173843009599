import React from "react"
import Img from "gatsby-image"
import {
  banner,
  heading,
  subheading,
  textContent,
  profileImageColumn,
  image,
  content,
} from "./index.style"
import { useStaticQuery, graphql } from "gatsby"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      file(relativePath: { eq: "display.png" }) {
        childImageSharp {
          fixed(height: 205, width: 195) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <section css={banner}>
      <div css={content}>
        <div css={textContent}>
          <h1 css={heading}>
            Hi, I’m Musta <br />& I’m a software engineer.
          </h1>
          <h2 css={subheading}>This is my journey so far.</h2>
        </div>
        <div css={profileImageColumn}>
          <Img css={image} fixed={data.file.childImageSharp.fixed} />
        </div>
      </div>
    </section>
  )
}
