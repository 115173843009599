import React from "react"
import { container, secondarySection, primarySection } from "./index.style"

interface Props {
  children: React.ReactNode
  primary?: boolean
  className?: string
}

export default function Section(props: Props) {
  const { children, primary, className } = props
  return (
    <section
      css={[container, ...(!primary ? [secondarySection] : [primarySection])]}
      className={className}
    >
      {children}
    </section>
  )
}
