import { CSSObject } from "@emotion/core"
import {
  pink700,
  red900,
  dark800,
  defaultBoxShadow,
  containerPadding,
  dark1000,
} from "../Layout/index.style"

export const container: CSSObject = {
  backgroundColor: pink700,
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  width: "calc(100% - 12px)",
  maxWidth: "100%",
  height: "auto",
  boxSizing: "border-box",
  padding: containerPadding,
  color: dark1000,
}

export const primarySection: CSSObject = {
  marginLeft: "7px",
  boxShadow: `-7px 7px ${dark800},
  ${defaultBoxShadow}`,
  a: {
    color: dark800,
    ":hover": {
      backgroundColor: dark800,
      color: "white",
    },
  },
}

export const secondarySection: CSSObject = {
  marginRight: "7px",
  boxShadow: `7px 7px ${red900},
  ${defaultBoxShadow}`,
  textAlign: "right",
  a: {
    color: red900,
    textDecoration: `underline`,
    textDecorationColor: red900,
    ":hover": {
      backgroundColor: red900,
      color: "white",
      textDecoration: `underline`,
    },
  },
}
