import { CSSObject } from "@emotion/core"
import {
  getMediaQuery,
  BREAKPOINT,
  contentWidth,
  font,
} from "../containers/Layout/index.style"

const sectionWidthSM = "450px"
const sectionWidthMD = "335px"
const sectionHeightSM = "255px"
const sectionHeightMD = "270px"

export const container: CSSObject = {
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  fontFamily: font,
}

export const content: CSSObject = {
  margin: "auto",
  marginTop: "20px",
  width: "100%",
  [getMediaQuery(BREAKPOINT.SM)]: {
    width: contentWidth,
  },
}

export const summaryBoxes: CSSObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "33px",
  height: "525px",
  Section: {
    width: sectionWidthSM,
    height: sectionHeightSM,
  },
  [getMediaQuery(BREAKPOINT.SM)]: {
    height: "auto",
    flexDirection: "row",
    Section: {
      width: sectionWidthMD,
      height: sectionHeightMD,
      marginTop: "0px",
    },
  },
}

export const tripleHighlightedSections: CSSObject = {
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  marginBottom: "25px",
  [getMediaQuery(BREAKPOINT.SM)]: {
    flexDirection: "row",
    justifyContent: "space-equally",
    width: "100%",
  },
}

export const highlightedSection: CSSObject = {
  width: "100%",
  [getMediaQuery(BREAKPOINT.XS)]: {
    width: sectionWidthSM,
  },
  [getMediaQuery(BREAKPOINT.MD)]: {
    width: "100%",
  },
  display: "grid",
  color: "white",
  h3: {
    color: "white",
    textAlign: "center",
  },
  p: {
    color: "white",
  },
}

export const highlightedSectionIcons: CSSObject = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  margin: "auto",
  width: "auto",
  "ion-icon": {
    margin: "0 10px",
    fontSize: "25px",
  },
  [getMediaQuery(BREAKPOINT.LG)]: {
    "ion-icon": {
      margin: "0 10px",
      fontSize: "20px",
    },
  },
  [getMediaQuery(BREAKPOINT.LG)]: {
    "ion-icon": {
      margin: "0 10px",
      fontSize: "40px",
    },
  },
}
