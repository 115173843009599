import React from "react"

import {
  container,
  content,
  tripleHighlightedSections,
  highlightedSection,
  highlightedSectionIcons,
  summaryBoxes,
} from "./index.style"
import SEO from "../components/SEO"
import Banner from "../components/Banner"
import Section from "../containers/Section"
import P from "../components/P"
import H3 from "../components/H3"
import A from "../components/A"
import HighlightedSection from "../containers/HighlightedSection"

export default function IndexPage() {
  return (
    <div css={container}>
      <SEO title="Home" />
      <Banner />

      <div css={content}>
        <div css={summaryBoxes}>
          <Section primary={true}>
            <H3>I am…</H3>
            <P>
              a software engineer with over <u>3 years</u> of software
              development experience.
            </P>
            <P>
              I am also 1st class graduate of <u>BSc Computer Science</u> at
              King&apos;s College London
            </P>
            <P>I like to make/eat desserts and I am obsessed with Pizza.</P>
          </Section>
          <Section>
            <H3>Currently…</H3>
            <P>
              I work at{" "}
              <A href="https://www.zynstra.com/">Zynstra, an NCR Company</A> in
              Bath as a software developer, as part of the Zynstra Control
              Console team, in which I perform full-stack web development.
            </P>
            <P>
              The technologies I work with are ReactJs, Redux, Formik, Python,
              CouchJS, and Linux.
            </P>
          </Section>
        </div>
        <div css={tripleHighlightedSections}>
          <HighlightedSection primary css={highlightedSection}>
            <H3>Front-end</H3>
            <P>
              Dynamic web apps with React and Angular web frameworks, developed
              with unit, integration and E2E testing
            </P>
            <div css={highlightedSectionIcons}>
              <ion-icon name="logo-react"></ion-icon>
              <ion-icon name="logo-angular"></ion-icon>
            </div>
          </HighlightedSection>
          <HighlightedSection tertiary css={highlightedSection}>
            <H3>Back-end</H3>
            <P>
              REST APIs, applications, scripts and file-templating using Python,
              NodeJS, Java and PHP
            </P>
            <div css={highlightedSectionIcons}>
              <ion-icon name="logo-python"></ion-icon>
              <ion-icon name="logo-nodejs"></ion-icon>
            </div>
          </HighlightedSection>
          <HighlightedSection secondary css={highlightedSection}>
            <H3>Mobile</H3>
            <P>
              Hybrid mobile application development for Android and iOS with
              Ionic Framework, and native Android applications with Java
            </P>
            <div css={highlightedSectionIcons}>
              <ion-icon name="logo-ionic"></ion-icon>
              <ion-icon name="logo-android"></ion-icon>
              <ion-icon name="logo-apple"></ion-icon>
            </div>
          </HighlightedSection>
        </div>
      </div>
    </div>
  )
}
