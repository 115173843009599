import { CSSObject } from "@emotion/core"
import {
  dark900,
  BREAKPOINT,
  getMediaQuery,
  contentWidth,
} from "../../containers/Layout/index.style"

export const banner: CSSObject = {
  width: "100%",
  height: "360px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  backgroundColor: "white",
  [getMediaQuery(BREAKPOINT.SM)]: {
    backgroundColor: dark900,
    height: "300px",
  },
}

export const content: CSSObject = {
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "space-between",
  [getMediaQuery(BREAKPOINT.SM)]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "auto",
    width: contentWidth,
  },
}

export const textContent: CSSObject = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: dark900,
  justifyContent: "center",
  textAlign: "center",
  padding: "10px",
  marginTop: "12px",
  [getMediaQuery(BREAKPOINT.SM)]: {
    padding: "0px",
    textAlign: "left",
    marginBottom: "0px",
  },
}

export const heading: CSSObject = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "white",
  margin: "0px",
}

export const subheading: CSSObject = {
  color: "white",
  fontSize: "24px",
  fontWeight: 300,
  margin: "12px 0px 5px 0px",
  [getMediaQuery(BREAKPOINT.SM)]: {
    marginTop: "20px",
  },
}

export const profileImageColumn: CSSObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [getMediaQuery(BREAKPOINT.SM)]: {
    width: "auto",
    float: "right",
  },
}

export const image: CSSObject = {
  marginLeft: "none",
  [getMediaQuery(BREAKPOINT.SM)]: {
    marginLeft: "auto",
  },
}
